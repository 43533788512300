import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import CryptoJS from 'crypto-js';
import BN from 'bn.js';
import './App.css';

// Define the Polygon Mainnet chain ID
const POLYGON_CHAIN_ID = '0x89'; // Hexadecimal for 137

// Network details for adding Polygon Mainnet
const polygonNetwork = {
  chainId: POLYGON_CHAIN_ID,
  chainName: 'Polygon Mainnet',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: ['https://polygon-rpc.com/'],
  blockExplorerUrls: ['https://polygonscan.com/'],
};

function App() {
  // State variables
  const [walletConnected, setWalletConnected] = useState(false);
  const [paymentVerified, setPaymentVerified] = useState(false);
  const [senderWalletAddress, setSenderWalletAddress] = useState('');
  const [walletStatus, setWalletStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadVisible, setUploadVisible] = useState(false);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  // Refs for form inputs
  const receiverWalletInputRef = useRef(null);
  const receiverTelegramInputRef = useRef(null);
  const senderEmailInputRef = useRef(null);
  const senderTelegramInputRef = useRef(null);
  const fileInputRef = useRef(null);

  // API Base URL
  const API_BASE_URL = 'https://chaintransfer.io/api';


  // Function to show loading indicator
  const showLoading = () => {
    document.getElementById('loading-indicator').classList.remove('hidden');
  };

  // Function to hide loading indicator
  const hideLoading = () => {
    document.getElementById('loading-indicator').classList.add('hidden');
  };

  // Function to switch to the Polygon network
  const switchToPolygonNetwork = async () => {
    try {
      // Try to switch to the Polygon network
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: POLYGON_CHAIN_ID }],
      });
      return true;
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [polygonNetwork],
          });
          return true;
        } catch (addError) {
          console.error('Failed to add the Polygon network:', addError);
          return false;
        }
      } else {
        console.error('Failed to switch to the Polygon network:', switchError);
        return false;
      }
    }
  };

  // Function to validate Ethereum wallet addresses
  const validateWalletAddresses = (addresses) => {
    if (!addresses) return false;
    const addressArray = addresses.split(',').map(addr => addr.trim());
    return addressArray.every(addr => Web3.utils.isAddress(addr));
  };

  // Function to validate file size (max 2GB per file)
  const validateFileSize = (files) => {
    const maxSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        alert(`File "${files[i].name}" exceeds the 2GB size limit.`);
        return false;
      }
    }
    return true;
  };

  // Connect Wallet Logic
  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const address = accounts[0];
        setSenderWalletAddress(address);
        setWalletStatus(`Connected: ${address}`);
        setWalletConnected(true);

        console.log(`Connected wallet address: ${address}`); // Debug

        // Check if the user is on the correct network
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        console.log('Connected chainId:', chainId); // Debug
        if (chainId !== POLYGON_CHAIN_ID) {
          const switched = await switchToPolygonNetwork();
          if (!switched) {
            alert('Please switch to the Polygon network to proceed.');
            return;
          }
        }

        // Automatically check for pending files
        checkForPendingFiles();
      } catch (error) {
        console.error("Error connecting wallet:", error);
        setWalletStatus('Failed to connect wallet.');
        setWalletConnected(false);
      }
    } else {
      alert('MetaMask is not installed!');
    }
  };

  // Payment Logic
  const pay = async () => {
    if (!walletConnected) {
      alert('Please connect your wallet before making a payment.');
      return;
    }

    const amountInMatic = '1'; // 1 MATIC
    const receiverWallet = '0x1ee58432b3015a180462c422fb1a30635267ef9d'; // Replace with your recipient wallet address

    console.log('Amount in MATIC (before conversion to Wei):', amountInMatic); // Debug

    try {
      // Create a Web3 instance
      const web3 = new Web3(window.ethereum);

      // Convert 1 MATIC to Wei using `BN` from 'bn.js'
      const amountInWeiBN = new BN(web3.utils.toWei(amountInMatic, 'ether'));
      console.log('Amount in Wei (after conversion):', amountInWeiBN.toString()); // Debug

      // Convert to Hexadecimal using `BN`
      const amountInHex = '0x' + amountInWeiBN.toString(16); // Convert BN to Hexadecimal
      console.log('Amount in Hex (after conversion to Hex):', amountInHex); // Debug

      // Set gas parameters
      const gasLimit = new BN('21000'); // Standard gas limit for simple transfers
      const gasPriceBN = new BN(web3.utils.toWei('30', 'gwei')); // 30 Gwei
      console.log('Gas Price in Wei (30 Gwei):', gasPriceBN.toString()); // Debug

      // Convert Gas Price and Limit to Hexadecimal
      const gasPriceInHex = '0x' + gasPriceBN.toString(16);
      const gasLimitInHex = '0x' + gasLimit.toString(16);
      console.log('Gas Price in Hex:', gasPriceInHex); // Debug
      console.log('Gas Limit in Hex:', gasLimitInHex); // Debug

      // Create transaction parameters
      const transactionParameters = {
        to: receiverWallet,
        from: senderWalletAddress,
        value: amountInHex,
        gas: gasLimitInHex,
        gasPrice: gasPriceInHex,
      };

      console.log('Final Transaction Parameters:', transactionParameters); // Debug

      // Send transaction request through MetaMask and capture the transaction hash
      const transactionHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });

      console.log('Transaction Hash:', transactionHash); // Debug
      setPaymentStatus('Payment sent successfully. Verifying payment...');
      setPaymentVerified(false); // Reset payment verification
      setUploadVisible(false); // Hide upload section until verification

      // Start verifying the payment using the transaction hash
      await verifyPayment(transactionHash); // Call the verification function

    } catch (error) {
      if (error.code === 4001) {
        setPaymentStatus('Transaction cancelled by user.');
      } else {
        console.error('Error processing payment:', error);
        setPaymentStatus('Error processing payment. Please try again.');
      }
    }
  };

  // Function to verify the payment periodically
  const verifyPayment = async (transactionHash) => {
    const web3 = new Web3(window.ethereum);
    let receipt = null;
    const maxAttempts = 24; // Total duration: 24 * 5 = 120 seconds
    let attempts = 0;

    // Polling the transaction receipt periodically
    while (!receipt && attempts < maxAttempts) {
      attempts += 1;
      console.log(`Checking transaction status... Attempt ${attempts}/${maxAttempts}`);

      try {
        // Fetch the transaction receipt
        receipt = await web3.eth.getTransactionReceipt(transactionHash);
        if (receipt) {
          console.log('Transaction confirmed:', receipt); // Debug
          setPaymentStatus('Payment verified successfully.');
          setPaymentVerified(true);
          setUploadVisible(true); // Show the upload section
          break;
        } else {
          console.log('Transaction not yet confirmed. Retrying...');
        }
      } catch (error) {
        console.error('Error checking transaction receipt:', error);
      }

      await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait 5 seconds before retrying
    }

    if (!receipt) {
      setPaymentStatus('Payment verification failed. Transaction may not have been confirmed.');
      setPaymentVerified(false);
      setUploadVisible(false);
    }
  };

  // File Upload Logic
  const uploadFiles = async () => {
    if (!paymentVerified) {
      alert('Please complete the payment before uploading files.');
      return;
    }

    const receiverWalletInput = receiverWalletInputRef.current.value;
    if (!validateWalletAddresses(receiverWalletInput)) {
      alert('Please enter valid receiver wallet address(es), separated by commas.');
      return;
    }

    const fileInput = fileInputRef.current;
    if (fileInput.files.length === 0) {
      alert('Please select at least one file to upload.');
      return;
    }

    if (!validateFileSize(fileInput.files)) {
      return; // Validation message already shown
    }

    const formData = new FormData();
    for (let i = 0; i < fileInput.files.length; i++) {
      formData.append('file', fileInput.files[i]);
    }
    formData.append('sender_wallet', senderWalletAddress);
    formData.append('sender_email', senderEmailInputRef.current.value);
    formData.append('wallets', receiverWalletInput);
    formData.append('telegram_username', senderTelegramInputRef.current.value);

    try {
      // Show progress bar
      const progressBar = document.getElementById('progress-bar');
      const progressBarInner = document.getElementById('progress-bar-inner');
      progressBar.style.display = 'block';
      progressBarInner.style.width = '0%';
      progressBarInner.innerText = '0%';

      setUploadStatus('Uploading files...');

      const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          progressBarInner.style.width = percentCompleted + '%';
          progressBarInner.innerText = percentCompleted + '%';
        },
      });

      if (response.status === 200) {
        setUploadStatus("Files uploaded successfully.");
        // Clear the file input
        fileInput.value = '';
      } else {
        setUploadStatus(`Error uploading file: ${response.data.error}`);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setUploadStatus(`Error uploading file: ${error.response.data.error}`);
      } else {
        setUploadStatus('Error uploading file.');
      }
    } finally {
      // Hide progress bar
      const progressBar = document.getElementById('progress-bar');
      const progressBarInner = document.getElementById('progress-bar-inner');
      progressBar.style.display = 'none';
      progressBarInner.style.width = '0%';
      progressBarInner.innerText = '';
    }
  };

  // Function to check for pending files and display notification
  const checkForPendingFiles = async () => {
    if (!walletConnected) {
      return;
    }

    try {
      showLoading(); // Show loading indicator
      console.log('Checking for pending files for wallet:', senderWalletAddress);

      const response = await axios.get(`${API_BASE_URL}/pending_files`, {
        params: {
          wallet: senderWalletAddress,
        },
      });

      hideLoading(); // Hide loading indicator
      console.log('Pending files response:', response.data);

      setPendingFiles(response.data.pending_files || []);
      setNotificationVisible(true);

      if (response.data.pending_files && response.data.pending_files.length > 0) {
        setNotificationMessage(`${response.data.pending_files.length} file(s) waiting for you:`);
      } else {
        setNotificationMessage('You have no files to download.');
      }
    } catch (error) {
      hideLoading(); // Hide loading indicator
      console.error('Error fetching pending files:', error);
      setNotificationMessage('Error fetching files.');
      setNotificationVisible(true);
    }
  };

  // Download File Function
  const downloadFile = async (ipfsHash, fileName) => {
    try {
      showLoading(); // Show loading indicator

      const response = await axios.get(`${API_BASE_URL}/download`, {
        params: {
          ipfs_hash: ipfsHash,
          wallet: senderWalletAddress,
        },
      });

      if (response.data.ipfs_url) {
        const encryptionKey = response.data.encryption_key;

        // Download the encrypted file
        const fileResponse = await axios.get(response.data.ipfs_url, {
          responseType: 'arraybuffer',
        });

        // Decrypt the file
        const decryptedData = decryptFile(fileResponse.data, encryptionKey);

        if (!decryptedData) {
          throw new Error('Decryption failed');
        }

        // Create a blob and download the file
        const blob = new Blob([decryptedData.buffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName || 'decrypted_file';
        link.click();

        // Remove the file from the pending list
        removePendingFile(fileName);

        alert('File downloaded and decrypted successfully.');
      } else {
        alert(response.data.error || 'Error downloading file.');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Error downloading file.');
    } finally {
      hideLoading(); // Hide loading indicator
    }
  };

  // Function to remove a file from the pending list
  const removePendingFile = (fileName) => {
    const updatedFiles = pendingFiles.filter((file) => file.file_name !== fileName);
    setPendingFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setNotificationMessage('You have no files to download.');
    }
  };

  // Decrypt File Function
  const decryptFile = (encryptedData, encryptionKeyBase64) => {
    try {
      // Convert encryption key from base64 to WordArray
      const encryptionKey = CryptoJS.enc.Base64.parse(encryptionKeyBase64);

      // Create Uint8Array from encryptedData
      const encryptedBytes = new Uint8Array(encryptedData);

      // Extract IV (first 16 bytes)
      const iv = encryptedBytes.slice(0, 16);
      const ivWordArray = CryptoJS.lib.WordArray.create(iv);

      // Extract encrypted content
      const encryptedContent = encryptedBytes.slice(16);
      const encryptedContentWordArray = CryptoJS.lib.WordArray.create(encryptedContent);

      // Decrypt
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedContentWordArray },
        encryptionKey,
        { iv: ivWordArray, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      );

      // Convert decrypted data to Uint8Array
      const decryptedData = decrypted.sigBytes > 0 ? decrypted : null;
      if (decryptedData) {
        const decryptedBytes = new Uint8Array(decryptedData.sigBytes);
        for (let i = 0; i < decryptedData.sigBytes; i++) {
          decryptedBytes[i] = (decryptedData.words[Math.floor(i / 4)] >> (24 - (i % 4) * 8)) & 0xff;
        }
        return decryptedBytes;
      } else {
        throw new Error('Decryption failed');
      }
    } catch (error) {
      console.error('Decryption error:', error);
      alert('Error decrypting file.');
      return null;
    }
  };

  // useEffect to check for pending files on component mount or wallet connection
  useEffect(() => {
    if (walletConnected) {
      checkForPendingFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletConnected]);

  return (
    <div className="App">
      <header>
        <h1>ChainTransfer</h1>
        <p>Secure, decentralized file-sharing using blockchain technology</p>
      </header>

      {/* Notification Area */}
      {notificationVisible && (
        <div id="notification" className="notification">
          <p>{notificationMessage}</p>
          {pendingFiles.length > 0 ? (
            <ul>
              {pendingFiles.map((file, index) => (
                <li key={index}>
                  <button
                    className="download-button"
                    onClick={() => downloadFile(file.ipfs_hash, file.file_name)}
                  >
                    {file.file_name}
                  </button>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      )}

      {/* Loading Indicator */}
      <div id="loading-indicator" className="loading-overlay hidden">
        <div className="loading-spinner"></div>
      </div>

      <main>
        {/* Connect Wallet Section */}
        <section id="connect-wallet" className="section">
          <h2>Connect Your Wallet</h2>
          <button onClick={connectWallet} className="primary-button">Connect MetaMask Wallet</button>
          <p
            id="wallet-status"
            className={`text-center ${walletConnected ? 'wallet-connected' : 'wallet-disconnected'}`}
          >
            {walletStatus}
          </p>
        </section>

        {/* Receiver Information Section */}
        <section id="receiver-info" className="section">
          <h2>Receiver Information</h2>
          <p>
            Enter the wallet address(es) or Telegram ID of the receiver. You can enter multiple wallet addresses separated by commas.
          </p>
          <div className="input-group">
            <input
              type="text"
              id="receiver-wallet-input"
              placeholder="Receiver's Wallet Address(es) (comma separated)"
              ref={receiverWalletInputRef}
            />
            <input
              type="text"
              id="receiver-telegram-input"
              placeholder="Receiver's Telegram ID (optional)"
              ref={receiverTelegramInputRef}
            />
          </div>
        </section>

        {/* Sender Information Section */}
        <section id="sender-info" className="section">
          <h2>Sender Information</h2>
          <p>
            Enter your email address and Telegram ID to receive notifications when your file is uploaded or downloaded.
          </p>
          <div className="input-group">
            <input
              type="email"
              id="sender-email-input"
              placeholder="Your Email Address (optional)"
              ref={senderEmailInputRef}
            />
            <input
              type="text"
              id="sender-telegram-input"
              placeholder="Your Telegram ID (optional)"
              ref={senderTelegramInputRef}
            />
          </div>
        </section>

        {/* Payment Section */}
        <section id="payment" className="section">
          <h2>Payment with MATIC</h2>
          <p>
            Pay 1 MATIC to share your file. Only after payment is verified can you upload the file.
          </p>
          <button onClick={pay} className="primary-button" disabled={paymentVerified}>
            Pay 1 MATIC to Share File
          </button>
          <p id="payment-status" className="text-center">{paymentStatus}</p>
        </section>

        {/* File Upload Section */}
        {uploadVisible && (
          <section id="upload-file" className="section">
            <h2>Upload File to IPFS</h2>
            <p>Upload your files securely to IPFS. Maximum file size per file: 2GB.</p>
            <input type="file" id="file-input" multiple ref={fileInputRef} />
            <button onClick={uploadFiles} className="primary-button">Upload to IPFS</button>
            <div id="progress-bar" className="progress-bar" style={{ display: 'none' }}>
              <div id="progress-bar-inner" className="progress-bar-inner"></div>
            </div>
            <p id="upload-status" className="upload-status">{uploadStatus}</p>
          </section>
        )}
      </main>

      <footer>
        <p>&copy; 2024 ChainTransfer. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
